import React, { useEffect, useState } from "react";

const App = () => {
  const [token, setToken] = useState(":(");

  useEffect(() => {
    fetch("get-token")
      .then((res) => res.json())
      .then((data) => {
        setToken(data.token);
        console.log("Token Set!");
      });
  }, []);

  return <h1>{token}</h1>;
};

export default App;
